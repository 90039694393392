// Variables and overrides
$imperial-core-colours: (
  "navy": #002147,
  "imperialblue": #003e74,
  "lightgrey": #ebeeee,
  "coolgrey": #9d9d9d,
  "lightblue": #d4effc,
);

$theme-colors: (
  "primary": map-get($imperial-core-colours, "imperialblue"),
);

// Import Bootstrap styles
@import "~bootstrap/scss/bootstrap.scss";

// Import Merriweather font
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700&display=swap");

// Styles
.icrpc-title {
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.hero-header-container {
  width: 100%;

  &.hero-large {
    // Set height based on breakpoint
    height: 25vh; // 25vh by default
    @include media-breakpoint-up(md) {
      height: 50vh; // 50vh for larger screens
    }
  }

  &.hero-small {
    // Set height based on breakpoint
    height: 15vh; // 15vh by default
    @include media-breakpoint-up(md) {
      height: 30vh; // 30vh for larger screens
    }
  }

  position: relative;

  div {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .hero-header-bg {
    // (set background-position in component)
    background-size: cover;
  }

  .hero-header-fg {
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center;
    padding-top: 1rem;

    .center-flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img.hero-logo {
      width: 90vw;
      max-width: 670px;
    }
  }
}

.join-steps {
  .join-step-col {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    }

    .join-step-body {
      flex: 1;
    }

    .join-step-action-inline {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .join-step-action-row {
    @include media-breakpoint-down(md) {
      display: none;
    }

    margin-bottom: 1.5rem;
  }
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
}

footer {
  padding: 1rem 0.5rem;
  color: $white;
  background-color: map-get($imperial-core-colours, "navy");
}
